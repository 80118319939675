"use client"

function FAQs(props) {
    return (
        <section className={["main-section", props.className].join(' ')}>
            <div className="container">
                <div className="main-head mh2 reviews-head-faq">
                    <h3>Frequently asked questions</h3>
                </div>
                <div className="collapse-boxes-wrapper" id="collapse-boxes">
                    <div className="row justify-content-center faq-reviews-sec">
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQs