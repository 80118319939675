"use client"

function SVGImage(props) {
    return (
        <object
            data={props.url}
            type="image/svg+xml"></object>
    )
}

export default SVGImage