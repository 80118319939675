import { filter, omit, pick, shuffle, slice } from "lodash";
import { usePathname } from "next/navigation";
import Swal from 'sweetalert2'

export const jumpToSection = (target) => {
    const section = document.getElementById(target);
    section?.scrollIntoView({
        behavior: "smooth"
    });
}

export const isLoggedIn = () => {
    return localStorage.setItem("logged_in", true) ?? false;
}

export const Toast = (icon, title) => {
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });

    return Toast.fire({ icon, title })
}

export const valid_email = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const getFeatures = (type, limit) => {

    let features = [
        {
            slug: '/features/ad-blocker/',
            svg: '/assets/svg/features/icons/ad-blocker.svg',
            title: 'Ad Blocker',
            summary: 'Stay clear of annoying ads and pop-ups that break your experience.',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/aes-256-bit-encryption/',
            svg: '/assets/svg/features/icons/256-bit-aes-encryption.svg',
            title: 'AES 256-Bit Encryption',
            summary: 'OysterVPN secures your internet traffic with the best symmetric encryption protocol.',
            type: ['enjoy_digital_security_and_freedom']
        },
        {
            slug: '/features/log-less-policy/',
            svg: '/assets/svg/features/icons/logless-policy.svg',
            title: 'Zero-Logs Policy',
            summary: 'OysterVPN strictly follows a zero-logs policy. We do not record or spy on your activities.',
            type: ['enjoy_digital_security_and_freedom']
        },
        {
            slug: '/features/ddos-protection/',
            svg: '/assets/svg/features/icons/ddos-protection.svg',
            title: 'DDoS Protection',
            summary: 'OysterVPN servers keep you safe by actively preventing DDoS attacks.',
            type: ['enjoy_digital_security_and_freedom']
        },
        {
            slug: '/features/secure-protocols/',
            svg: '/assets/svg/features/icons/secure-protocols.svg',
            title: 'Secure Protocols',
            summary: 'Choose from the top VPN protocols such as OpenVPN, IKEv2, L2TP/IPsec, and PPTP.',
            type: ['enjoy_digital_security_and_freedom']
        },
        {
            slug: '/features/p2p-optimized-servers/',
            svg: '/assets/svg/features/icons/p2p-optimized-servers.svg',
            title: 'P2P Optimized Servers',
            summary: 'Enjoy safe and secure torrenting with OysterVPN P2P optimized servers.',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/multi-logins/',
            svg: '/assets/svg/features/icons/multi-logins.svg',
            title: 'Multi Logins',
            summary: 'Use up to 5 devices simultaneously with a single OysterVPN subscription.',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/dns-leak-protection/',
            svg: '/assets/svg/features/icons/dns-leak-protection.svg',
            title: 'DNS Leak Protection',
            summary: 'OysterVPN app is built to hide your web activity by preventing DNS queries from leaking.',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/unlimited-data/',
            svg: '/assets/svg/features/icons/unlimted-bandwidth.svg',
            title: 'Unlimited Data',
            summary: 'Get zero restrictions on data consumption for browsing, streaming, and playing games online.',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/ip-leak-protection/',
            svg: '/assets/svg/features/icons/ipv6-leak-protection.svg',
            title: 'IP Leak Protection',
            summary: 'An IP leak can expose your identity on the internet. But not with OysterVPN!',
            type: ['top_notch_protection']
        },
        {
            slug: '/features/nat-firewall/',
            svg: '/assets/svg/features/icons/nat-firewall.svg',
            title: 'NAT Firewall',
            summary: 'NAT Firewall Feature will add an extra layer of protection to block inbound traffic.',
            type: ['smart_and_secure_connectivity']
        },
        {
            slug: '/features/safe-servers/',
            svg: '/assets/svg/features/icons/safe-servers.svg',
            title: 'Safe Servers',
            summary: 'Enjoy secure and safe connectivity with OysterVPN\'s servers.',
            type: ['smart_and_secure_connectivity']
        },
        {
            slug: '/features/anti-malware/',
            svg: '/assets/svg/features/icons/anti-malware.svg',
            title: 'Anti-Malware Protection',
            summary: 'Our servers protects you by blocking potentially malicious websites.',
            type: ['smart_and_secure_connectivity']
        },
        {
            slug: '/features/isp-throttling/',
            svg: '/assets/svg/features/icons/isp-throttling.svg',
            title: 'ISP Throttling',
            summary: 'Reduce the risk of ISP throttling by hiding your web activity with OysterVPN\'s encryption.',
            type: ['top_notch_protection', 'smart_and_secure_connectivity']
        },
        {
            slug: '/features/kill-switch/',
            svg: '/assets/svg/features/icons/kill-switch.svg',
            title: 'Internet Kill Switch',
            summary: 'Kill switch protects your data and IP address even when the VPN connection breaks down.',
            type: ['smart_and_secure_connectivity']
        },
        {
            slug: '/features/split-tunneling/',
            svg: '/assets/svg/features/icons/split-tunneling.svg',
            title: 'Split Tunneling',
            summary: 'Split Tunneling allows you to specify which traffic passes through the VPN tunnel.',
            type: ['top_notch_protection', 'smart_and_secure_connectivity']
        }
    ];

    let filteredFeatures = features;
    // console.log(exclude)
    // if (exclude) {
    //     filteredFeatures = pick(features, { slug: `/features/${exclude}/` })
    // }

    if (type === "random") {
        return slice(shuffle(filteredFeatures), 0, limit);
    }

    return slice(filter(filteredFeatures, (x) => {
        return x.type.includes(type);
    }), 0, limit)
}