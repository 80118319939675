"use client"

import { useEffect, useState } from "react";

import "placeholder-loading/dist/css/placeholder-loading.min.css";
import PackagesNew from "@/app/(front)/buy-vpn/components/PackagesNew";
import PackagesLoader from "./PackagesLoader";

function PricingPlans(props) {

    const [packages, setPackages] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            const response = await fetch('/api/packages');
            const { data } = await response.json();

            setPackages(data)
            // setPackageId(data.find(x => x.recommended == true).package_id)
            // setSelectedPackage(data.find(x => x.recommended == true));
        }

        fetchData();
    }, [])

    return (

        <section className={["main-section", props.className].join(" ")}>
            <div className="container">
                <div className="main-head mh2">
                    <h3>{props.title ?? "Secure your digital life"}</h3>
                    {props.children && <p>{props.children}</p>}
                </div>
                <div className={`pricing-wrap blackfriday`}>
                    <div id="render-vpn-packages" className="packages d-flex">
                        {packages.length == 0 && (
                            <>
                                <PackagesLoader /><PackagesLoader /><PackagesLoader />
                            </>
                        )}
                        {packages.length > 0 && (
                            <PackagesNew
                                main={false}
                                defaultTheme={props.defaultTheme}
                                packages={packages} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingPlans