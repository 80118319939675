"use client"

function FAQItem(props) {
    return (
        <div key={props.title} className={["col-lg-9 col-md-10 col-sm-12 col-12", props.className].join(" ")}>
            <div className="collapse-box">
                <div className="collapse-box-header">
                    <a href={"#collapseItem" + props.id} className={["collapse-box-button", props?.IsOpen ? "" : "collapsed"].join(" ")} data-bs-toggle="collapse" aria-expanded={props?.IsOpen ? "true" : "false"}>{props.title}</a>
                </div>
                <div id={"collapseItem" + props.id} className={["collapse-box-body collapse faq-items", props?.IsOpen ? "show" : ""].join(" ")} data-bs-parent="#collapse-boxes">
                    <div className="collapse-box-content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQItem