"use client"

import { getFeatures } from "@/utils/common";
import SVGImage from "./SVGImage";

export default function FeaturesList(props) {
    return (
        <section className={["main-section", props.className].join(' ')}>
            <div className="container">
                <div className="main-head mh2">
                    <h3>{props.title}</h3>
                    {props.children && <p>{props.children}</p>}
                </div>

                {props.styleType == "style1" &&
                    <div className="row gx-5">
                        {getFeatures(props.type, props.limit).map((feature) => (
                            <div key={feature.slug} className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="icon-verti-grid">
                                    <div className="icon-grid-img-sec">
                                        <SVGImage url={feature.svg} />
                                    </div>
                                    <div className="icon-grid-content">
                                        <a href={feature.slug}><h3>{feature.title}</h3></a>
                                        <p>{feature.summary}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }

                {props.styleType == "style2" &&
                    <div className="row gx-5 justify-content-center">
                        {getFeatures(props.type, props.limit).map((feature) => (
                            <div key={feature.slug} className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="icon-grid">
                                    <div className="icon-grid-sec">
                                        <SVGImage url={feature.svg} />
                                    </div>
                                    <div className="icon-grid-content">
                                        <h3><a href={feature.slug}>{feature.title}</a></h3>
                                        <p>{feature.summary}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }

                {props.styleType == "style3" &&
                    <div className="row gx-5 justify-content-center">
                        {getFeatures(props.type, props.limit).map((feature) => (
                            <div key={feature.slug} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="icon-grid icon-grid-box">
                                    <div className="icon-grid-third">
                                        <SVGImage url={feature.svg} />
                                    </div>
                                    <div className="icon-grid-content">
                                        <a href={feature.slug}><h3>{feature.title}</h3></a>
                                        <p>{feature.summary}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </section>
    )
}
