'use client';

import { createContext, useContext, useEffect, useState } from 'react';

export const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children, cookiesData }) => {

	const [shortcodeDetails, setShortcodeDetails] = useState({})
	const [subscription, setSubscription] = useState({})

	const userInfoCookie = cookiesData.find(c => c.name === "userinfo") ? JSON.parse(cookiesData.find(c => c.name === "userinfo").value) : {};

	const [userInfo, setUserInfo] = useState(userInfoCookie);

	const token = cookiesData.find(c => c.name === "token") ? cookiesData.find(c => c.name === "token").value : null;

	const [userIP, setuserIP] = useState({
		query: '0.0.0.0',
		as: 'Intranet'
	});

	useEffect(() => {
		const getShortcodeDetails = async () => {
			const response = await fetch("/api/shortcode_details/", {
				next: { revalidate: 10 }
			});
			const { data } = await response.json();
			setShortcodeDetails(data);
		}

		const getVisitorIp = async () => {
			const res = await fetch('/api/ipinfo', {
				method: "POST"
			});
			const result = await res.json();

			setuserIP({
				query: result.query,
				as: result.as,
				country: result.country,
				city: result.city,
				region: result.regionName,
				isp: result.isp
			});
		}

		getVisitorIp();
		getShortcodeDetails();
	}, [])

	useEffect(() => {

		if (token === null) return;

		const subscriptionDetailsRequest = async () => {
			const response = await fetch('/api/subscriptions/detail/', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'X-PLATFORM': 'web'
				},
				body: JSON.stringify({
					token
				}),
				next: { revalidate: 10 }
			});

			const { data } = await response.json();

			setSubscription(data);
		}

		subscriptionDetailsRequest();
	}, [token])


	return (
		<ThemeContext.Provider value={{ shortcodeDetails, userIP, subscription, userInfo }}>
			{children}
		</ThemeContext.Provider>
	)
};

export const useThemeContext = () => useContext(ThemeContext);