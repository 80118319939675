"use client"

import { useThemeContext } from "@/app/theme-provider";
import SVGImage from "@/components/SVGImage";

export default function CustomerSupport(props) {

    const { shortcodeDetails } = useThemeContext();

    return (
        <>
            <section className={`main-section ${props.className}`}>
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div className="icon-lg-grid">
                                <div className="customer-support-img">
                                    <SVGImage url="/assets/svg/home/customer-support-new.svg" />
                                </div>

                                <div className="icon-grid-content">
                                    <h2>24/7 Customer Support</h2>
                                    <p>Real humans, not bots. We make sure you can connect with us quickly via live chat and email for troubleshooting issues and queries.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col d-none d-lg-block d-xl-block">
                            <div className="vert-divider"></div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div className="icon-lg-grid">
                                <div className="customer-support-img">
                                    <SVGImage url="/assets/svg/home/money-back.svg" />
                                </div>
                                <div className="icon-grid-content">
                                    <h2>{shortcodeDetails?.money_back} Money-Back Guarantee</h2>
                                    <p>We back up your purchase with a {shortcodeDetails?.money_back} money-back guarantee. Don't like OysterVPN? Just reach out to us and we'll refund your money.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}