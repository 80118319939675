"use client"

import { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Link from "next/link";
import Image from "next/image";

import styles from './ServerMap.module.css'
import { useThemeContext } from "@/app/theme-provider";

export default function ServersMap() {
    const [servers, setServers] = useState([])

    const { shortcodeDetails } = useThemeContext();

    useEffect(() => {
        const getServers = async () => {
            const res = await fetch('/api/servers/');
            const { data } = await res.json();

            const filteredServers = data.map((server) => {
                return {
                    title: server.name,
                    ip: server.ip_address,
                    latitude: parseFloat(server.latitude),
                    longitude: parseFloat(server.longitude),
                    color: "#ffffff",
                    city: server.city?.name ?? null,
                    flag: `/assets/images/flags/${server.country.iso2}.svg`
                }
            });

            setServers(filteredServers);
        }

        getServers()
    }, [])

    useEffect(() => {

        if (servers.length <= 0) return;

        am4core.useTheme(am4themes_animated);

        let map = am4core.create("vectorMap", am4maps.MapChart);
        map.geodata = am4geodata_worldLow;
        map.seriesContainer.draggable = false;
        map.seriesContainer.resizable = false;
        map.seriesContainer.dragWhileResize = false;
        map.chartContainer.wheelable = false;
        map.homeZoomLevel = 1.05;
        map.maxZoomLevel = 2;

        // Set projection
        map.projection = new am4maps.projections.NaturalEarth1();

        // Create map polygon series
        var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

        // Exclude Antartica
        polygonSeries.exclude = ["AQ"];

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.draggable = false
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#ffffff");
        polygonTemplate.realStroke = am4core.color("#ffffff");
        polygonTemplate.polygon.fillOpacity = 0.1;
        polygonTemplate.polygon.strokeWidth = 0.2;
        polygonTemplate.polygon.strokeOpacity = 0.5;

        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#367B25");

        // Add image series
        var imageSeries = map.series.push(new am4maps.MapImageSeries());
        imageSeries.draggable = false
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.tooltip.getFillFromObject = false;
        imageSeries.tooltip.getStrokeFromObject = false;
        imageSeries.tooltip.background.fill = am4core.color("#ffffff");

        let marker = imageSeries.mapImages.template.createChild(am4core.Image);
        marker.href = "/assets/images/map-marker.png";
        marker.width = 20;
        marker.height = 30;
        marker.nonScaling = true;
        marker.tooltipHTML = `<div style="display: flex; padding: 10px;">
            <div style="width: 50px;margin-right: 10px;width: 50px;width: 40px;"><img src="{flag}" width="50" height="40" /></div>
            <div style="text-align: left;">
                <h4 style="color: #001d2f;font-size: 16px;font-weight: 600;margin: 0;">{title} <small style="font-size: 12px;">({ip})</small></h4>
                <p style="color: #001d2f;font-size: 12px;margin: 0;">{city}</p>
            </div>
        </div>`;
        marker.tooltipX = 10;
        marker.tooltipY = 0;
        marker.horizontalCenter = "middle";
        marker.verticalCenter = "bottom";

        var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.radius = 2;
        circle.propertyFields.fill = "color";
        circle.nonScaling = true;

        var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle2.radius = 2;
        circle2.propertyFields.fill = "color";

        circle2.events.on("inited", function (event) {
            animateBullet(event.target);
        })

        function animateBullet(circle) {
            var animation = circle.animate([{ property: "scale", from: 1 / map.zoomLevel, to: 8 / map.zoomLevel },
            { property: "opacity", from: .5, to: 0 }], 2000, am4core.ease.circleOut);
            animation.events.on("animationended", function (event) {
                animateBullet(event.target.object);
            })
        }

        var colorSet = new am4core.ColorSet();
        imageSeries.data = servers;
    }, [servers])

    return (
        <section className="main-section bg-blue-radial-gradient">
            <div className="container-fluid">
                <div className="main-head mh-white">
                    <h3>{shortcodeDetails?.servers_count} Servers In {shortcodeDetails?.countries_count} Countries &amp; Growing</h3>
                    <p className="text-extra-large font-weight-600">We made our VPN servers available in popular destinations of the world.</p>
                </div>
                <div className="map-wrapper">
                    <div className={styles.vectorMapBox} id="vectorMap"></div>
                    <div className={styles.vectorMapImage}>
                        <Image src="/assets/images/vector-map.png" width={1143} height={572} alt="Servers" />
                    </div>
                    <Link href="/servers" className="mycta-link mycta-link-white">Explore servers <i className="fa fa-angle-right"></i></Link>
                </div>
            </div>
        </section>
    )
}