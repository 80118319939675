"use client"
import "placeholder-loading/dist/css/placeholder-loading.min.css";


function PackagesLoader() {
    return (
        <div className="ph-item align-items-center ph-item-colum" style={{background: "transparent"}}>
            <div className="ph-col-12 pt-4">
                <div className="ph-row justify-content-center">
                    <div className="ph-col-10 big"></div>
                </div>
                <br />
                <div className="ph-row justify-content-evenly">
                    <div className="ph-col-2"></div>
                    <div className="ph-col-4"></div>
                    <div className="ph-col-2"></div>
                </div>
                <br />
                <div className="ph-row justify-content-center">
                    <div className="ph-col-4 ph-picture big pb-5 rounded-pill"></div>
                </div>
                <br />
                <div className="ph-row justify-content-center">
                    <div className="ph-col-10"></div>
                    <div className="ph-col-10"></div>
                    <div className="ph-col-10"></div>
                </div>
            </div>
        </div>
    )
}

export default PackagesLoader