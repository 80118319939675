"use client"

import { useEffect } from "react";

export default function BubbleAnimation({ defaultTheme }) {

    useEffect(() => {
        const colors = ["#ffffff"];
        const numBalls = 10;
        const bubbles = [];

        for (let i = 0; i < numBalls; i++) {
            let bubble = document.createElement("div");
            bubble.classList.add("home-banner-bubbles");
            bubble.style.background = colors[Math.floor(Math.random() * colors.length)];
            bubble.style.left = `${Math.floor(Math.random() * 100)}vw`;
            bubble.style.top = `${Math.floor(Math.random() * 100)}vh`;
            bubble.style.transform = `scale(${Math.random()})`;
            bubble.style.width = `${Math.floor(Math.random() * 15)}em`;
            bubble.style.height = bubble.style.width;

            bubbles.push(bubble);
            document.querySelector('.home-banner-bubbles-wrap').append(bubble);
        }

        bubbles.forEach((el, i, ra) => {
            let to = {
                x: Math.random() * (i % 2 === 0 ? -11 : 11),
                y: Math.random() * 12
            };

            let anim = el.animate(
                [
                    { transform: "translate(0, 0)" },
                    { transform: `translate(${to.x}rem, ${to.y}rem)` }
                ],
                {
                    duration: (Math.random() + 1) * 2000,
                    direction: "alternate",
                    fill: "both",
                    iterations: Infinity,
                    easing: "ease-in-out"
                }
            );
        });
    }, [])


    return (
        <>
            <div className="home-banner-ripples-wrap">
                <div className={`home-banner-ripples home-banner-ripples-${defaultTheme}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="home-banner-bubbles-wrap"></div>
        </>
    )
}
